import * as React from 'react';

import {
  ModalContext,
  OverlayModal,
} from '../../global';
import {AuthForm} from '..';
import {useAuthView} from '../../../hooks/useAuthView';
import {Box} from '../../layout/Box';

export const LoginModal: React.FC = () => {
  const {activeProps} = React.useContext(ModalContext);
  const authProps = useAuthView();
  const props = activeProps.login;

  return (
    <OverlayModal
      name="login"
      closeButton
    >
      <Box maxWidth="450px">
        <AuthForm {...authProps} {...props} name="login" />
      </Box>
    </OverlayModal>
  );
};
